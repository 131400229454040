<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <ProgressBarComponent
          v-show="isLoading"
          :title="'view'"
        />
      </v-col>
    </v-row>
    <v-row
      v-show="!isLoading"
      justify="center"
      class="mt-0"
    >
      <v-col cols="11">
        <v-row class="text-left">
          <v-col
            cols="12"
            class="py-lg-2 py-md-1"
          >
            <h3 class="font-rs w-bold title-5 pb-2">
              {{ items[currentItem].title }}
            </h3>
            <span class="theme-default-texts font-r w-normal par-7">
              {{ items[currentItem].desc }}
            </span>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col
            cols="10"
            class="text-center pb-0"
          >
            <v-row v-if="editTarget !== enumOptions.locker">
              <v-col v-if="!isLocked">
                <ConfirmPasswordComponent
                  v-if="currentItem === enumOptions.changePassword"
                  :confirm="confirmPassword"
                  :email="email"
                  @new-password-completed="setNewPassword"
                />
                <ConfirmCodeComponent
                  v-if="showConfirmCodeComponent"
                  @full-code="setCodeToUnlockEdit"
                  @resend-code="resendCode"
                />
                <PhoneComponent
                  v-if="currentItem === enumOptions.phoneNumber"
                  @phone-number="setNewPhone"
                />
                <v-text-field
                  v-if="currentItem === enumOptions.email"
                  v-model="email"
                  name="email"
                  label="Your email"
                  :rules="[rules.required, rules.email]"
                  required
                />
                <v-text-field
                  v-if="currentItem === enumOptions.names"
                  v-model="firstName"
                  name="firstName"
                  label="First name"
                  :rules="[rules.required, rules.names]"
                  required
                />
                <v-text-field
                  v-if="currentItem === enumOptions.names"
                  v-model="lastName"
                  name="lastName"
                  label="Lastname"
                  :rules="[rules.required, rules.names]"
                  required
                />
              </v-col>
              <v-col v-if="isLocked && currentItem !== enumOptions.passwordToUnlockEdit">
                <ConfirmCodeComponent @full-code="setCodeToUnlockEdit" />
              </v-col>
              <v-col v-if="isLocked && !userGoogleId && currentItem === enumOptions.passwordToUnlockEdit">
                <PasswordComponent
                  :label="'Password'"
                  :placeholder="'Enter your password'"
                  @new-password="setPasswordToUnlockEdit"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col
            cols="10"
            class="pb-8 text-center"
          >
            <v-btn
              ripple
              depressed
              block
              color="primary"
              class="resp-btn mt-15 rounded-lg text-capitalize"
              @click="confirmChange"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ConfirmPasswordComponent from '@/components/common/ConfirmPasswordComponent';
import ConfirmCodeComponent from  '@/components/common/ConfirmCodeComponent';
const PhoneComponent = () => import(/* webpackChunkName: "PhoneComponent" */ '@/components/common/PhoneComponent');
import ValidatorComponent from '@/components/common/ValidatorComponent';
import PasswordComponent from '@/components/common/PasswordComponent';
import ShareComponent from "@/components/common/ShareComponent";
import ProgressBarComponent from "@/components/common/ProgressBarComponent";
import { mapActions, mapGetters } from "vuex";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ConfirmPasswordComponent, ValidatorComponent, PasswordComponent, PhoneComponent, ConfirmCodeComponent, ShareComponent, ProgressBarComponent },
  data: () => ({
    isLoading: true,
    currentItem: 0,
    editTarget: 0,
    isLocked: true,
    firstName: null,
    lastName: null,
    password: null,
    confirmPassword: 0,
    email: null,
    googleId: null,
    phoneNumber: null,
    isVerify: false,
    fullCode: null,
    suggestResendCode: false,
    codeConfirmed: false,
    rules: ValidatorComponent.data().rules,
    items: [
    { title: 'Locked', desc: 'Locked view' },
    { title: 'Your password', desc:'To protect your account, we require you to enter your password to make this change.' },
    { title: 'Change password', desc:'Please enter and confirm your new password.' },
    { title: 'Enter email code', desc: 'We just send you a code to your email, please enter that code here.'},
    { title: 'Enter new phone number', desc: 'Please enter your new US phone number.'},
    { title: 'Enter phone verification code', desc: 'We just send you a code to your new phone, please enter that code here.'},
    { title: 'Enter phone code', desc: 'We just send you a code to your phone, please enter that code here.'},
    { title: 'Enter your new email', desc: 'Enter here the new email you want to use for this account.'},
    { title: 'Enter your names', desc: 'Enter here your real names.'}],
    enumOptions: { locker: 0, passwordToUnlockEdit: 1, changePassword: 2, codeToUnlockEdit: 3, phoneNumber: 4, 
    codeToConfirmNewPhoneNumber: 5, codeToEditEmail: 6, email: 7, names: 8, lastnames: 9, codeToVerifyEmail: 3 },
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentProfile']),
    showConfirmCodeComponent() {
      return  this.currentItem === this.enumOptions.codeToConfirmNewPhoneNumber || 
              this.currentItem === this.enumOptions.codeToEditEmail || 
              this.currentItem === this.enumOptions.codeToVerifyEmail;
    },
    urlToShareReferralLink() {
      return process.env.VUE_APP_URL+ '/register?referralCode='+this.currentProfile.referralCode;
    },
    userGoogleId() {
      return this.currentUser?.google_id;
    }
  },
  methods: {
    ...mapActions('auth', ['checkPassword', 'deleteAccount', 'sendConfirmationCode',
    'sendDeleteCode', 'confirmDeleteCode', 'sendCodeToPhone', 'verifyPhoneCode', 'sendCodeToEmail', 'verifyEmailCode', 'logout']),
    ...mapActions('profile', ['updateProfileNames', 'updateEmail']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    editMode(target) {
      this.$emit('edit');
      this.editTarget = target;
    },
    resetEdit() {
      this.currentItem = this.enumOptions.locker;
      this.editTarget = this.enumOptions.locker;
      this.isLocked = true;
      this.setTopMenuTitle('Profile');
      this.$router.back();
    },
    confirmChange() {
      switch (this.currentItem) {
        case this.enumOptions.passwordToUnlockEdit:
          this.validatePasswordToUnlockEdit();
          break;
        case this.enumOptions.codeToUnlockEdit:
          this.validateEmailCodeToUnlockEdit();
          break;
        case this.enumOptions.codeToEditEmail:
          this.validatePhoneCodeToUnlockEdit();
          break;
        case this.enumOptions.changePassword:
          this.validateChangePassword();
          break;
        case this.enumOptions.phoneNumber:
          this.validatePhoneNumberChange();
          break;
        case this.enumOptions.codeToConfirmNewPhoneNumber:
          this.validatePhoneCodeToConfirmNewPhone();
          break;
        case this.enumOptions.email:
          this.validateEmailChange();
          break;
        case this.enumOptions.names:
          this.validateNames();
          break;
      }
    },
    validatePasswordToUnlockEdit() {
      if (this.currentUser.google_id === null && this.password) {
        this.checkPassword({ 'password': this.password }).then( response => {
          if (response.data?.success) {
            this.isLocked = false;
            this.currentItem = this.editTarget;
          }
        }).catch(error => {
          this.$toasted.global.showError({message: error.response.data?.message || error.message});
        });
      }
    },
    validateEmailCodeToUnlockEdit() {
      this.verifyEmailCode({ code: this.fullCode }).then( response => {
        if(this.editTarget === this.enumOptions.email) {
          if(response.data.success) {
            this.$toasted.global.showSuccess({message: response.data.message});
            this.isLocked = false;
            this.resetEdit();
          }
        } else {
          this.currentItem = this.enumOptions.phoneNumber;
          this.isLocked = false;
        }
      }).catch( error => {
        this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Failed, please try again'});
      });
    },
    validatePhoneCodeToUnlockEdit() {
      this.verifyPhoneCode({ phone_number: this.phoneNumber, code: this.fullCode }).then( response => {
        this.$toasted.global.showSuccess({message: response.data.message});
        this.isLocked = false;
        this.currentItem = this.enumOptions.email;
      }).catch( error => {
        this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Failed, please try again'});
      });
    },
    validateChangePassword() {
      this.confirmPassword++;
    },
    async validatePhoneNumberChange() {
      const isValidField = ValidatorComponent.data().rules.phoneNumber(this.phoneNumber);

      if ( isValidField === true ) {
        this.sendConfirmationCode({phone_number: this.phoneNumber}).then( response => {
          this.$toasted.global.showSuccess({message: response.data.message});
          this.isVerify = false;
          this.currentItem = this.enumOptions.codeToConfirmNewPhoneNumber;
        }).catch( error => {
          this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Phone verification failed, please try again'});
        });

      } else {
        this.$toasted.global.showError({message: 'Invalid field, please check again: '+isValidField});
      }
    },
    async validatePhoneCodeToConfirmNewPhone() {
      const isValidField = ValidatorComponent.data().rules.phoneNumber(this.phoneNumber);

      if ( isValidField === true ) {
        this.verifyPhoneCode({ phone_number: this.phoneNumber, code: this.fullCode }).then( response => {
          this.$toasted.global.showSuccess({message: response.data.message});
          this.isVerify = true;
          this.resetEdit();
        }).catch( error => {
          this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Code verification failed, please try again'});
        });
      } else {
        this.$toasted.global.showError({message: 'Invalid field, please check again'});
      }
    },
    async validateEmailChange() {
      const isValidField = ValidatorComponent.data().rules.email(this.email);

      if ( isValidField === true ) {
        this.updateEmail({ email: this.email }).then( response => {
          this.$toasted.global.showSuccess({message: response.data.message});
          this.currentItem = this.enumOptions.codeToVerifyEmail;
        }).catch( error => {
          this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Failed, please try again'});
        });
      } else {
        this.$toasted.global.showError({message: 'Invalid field, please check again'});
      }
    },
    editPassword() {
      this.currentItem = this.enumOptions.passwordToUnlockEdit;
      this.editMode(this.enumOptions.changePassword);
      this.isLoading = false;
    },
    setNewPassword(newPassword) {
      this.password = newPassword;
      this.resetEdit();
    },
    editPhone() {
      this.sendCodeToEmail().then( response => {
        this.$toasted.global.showSuccess({message: response.data.message});
        this.currentItem = this.enumOptions.codeToUnlockEdit;
        this.editMode(this.enumOptions.phoneNumber);
        this.isLoading = false;
      }).catch( error => {
        this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Failed, please try again'});
      });
    },
    setNewPhone(newPhone) {
      this.phoneNumber = newPhone;
    },
    editEmail() {
      this.sendCodeToPhone().then( response => {
        this.$toasted.global.showSuccess({message: response.data.message});
        this.currentItem = this.enumOptions.codeToEditEmail;
        this.editMode(this.enumOptions.email);
        this.isLoading = false;
      }).catch( error => {
        this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Failed, please try again'});
      });
    },
    editNames() {
      this.currentItem = this.enumOptions.names;
      this.editMode(this.enumOptions.names);
      this.isLocked = false;
      this.isLoading = false;
    },
    async validateNames() {
      const isValidFirstName = ValidatorComponent.data().rules.names(this.firstName);
      const isValidLastName = ValidatorComponent.data().rules.names(this.lastName);

      if( isValidFirstName === true && isValidLastName === true ) {
        const response = await this.updateProfileNames({ first_name: this.firstName, last_name: this.lastName });

        if(response.data) {
          this.$toasted.global.showSuccess({message: response.data.message});
          this.resetEdit();
        } else {
          this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Failed, please try again'});
        }
      }
    },
    setPasswordToUnlockEdit(password) {
      this.password = password;
    },
    setCodeToUnlockEdit(fullCode) {
      this.fullCode = fullCode;
    },
    async resendCode() {
      const response = await this.sendConfirmationCode({phone_number: this.phoneNumber});

      if(response.data.isValid){
        this.$toasted.global.showSuccess({message: response.data.message});
      }else{
        this.$toasted.global.showError({message: response.data.message});
      }
    },
  },
  mounted() {
    this.firstName = this.currentProfile.firstName;
    this.lastName = this.currentProfile.lastName;
    this.email = this.currentUser.email;
    this.phoneNumber = this.currentProfile.phoneNumber;

    switch(this.$route.params.field) {
      case "password":
        this.editPassword();
        break;
      case "email":
        this.editEmail();
        break;
      case "phone":
        this.editPhone();
        break;
      case "names":
        this.editNames();
        break;
    }
  }
}
</script>

<style scoped>
.theme-default-chip {
  display: flex;
  min-width: 234px;
  height: 56px !important;
}

.theme-sm-chip {
  width: 187px;
  height: 38px !important;
}

.theme-default-chip-input, .theme-default-chip-input:hover {
  background: #F1F1F5 !important;
  border-radius: 10px !important;
}

.theme-default-chip-file, .theme-default-chip-file:hover {
  background: #E2E2EA !important;
  border-radius: 10px !important;
}

.v-chip.theme-default-chip-input:before {
  background-color: initial;
}

.v-chip.theme-default-border {
  border-radius: 10px !important;
}

.clickable:hover {
  cursor: pointer;
}

.v-input >>> input, .theme-default-chips-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: #212121 !important;
}

.row-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
}

.btn-theme-icon {
  width: 56px !important;
  height: 56px !important;
}

div.v-card.card-box {
  box-shadow: none;
  border-radius: 16px;
}
.card-box-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #000000;
}
</style>
